import React, { useEffect } from "react";
import "./SetupLesson.styles.scss";
import Track from "../../Components/Track/Track";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import { useDispatch, useSelector } from "react-redux";
import {
  getTeacherLessons,
  setTeacherLessons,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import LessonInput from "../../Components/LessonInput/LessonInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteLesson,
  handleLessonChange,
  initial,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import AlertModalDelete from "../../../AdminApp/Components/AlertModalDelete/AlertModalDelete";
import { convertTimezoneTwentyFour } from "../../../SharedApp/Components/Timezone";
import Note from "../../Components/Note/Note";

export default function SetupLesson({
  track,
  customLinks,
  given,
  customClass,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { teacher } = useSelector((state) => state.teacher);
  const { user, timezone } = useSelector((state) => state.user);

  const fetchedSubjects = useSelector((state) => state.teacher.fetchedSubjects);

  const location = useLocation();

  let loc = location.pathname.includes("teachers") ? true : false;

  const [previousLessons, setPreviousLessons] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState();
  const [index, setIndex] = useState(loc ? undefined : undefined);
  const [lessons, setLessons] = useState(loc ? [initial] : []);
  const [refresh, setRefresh] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (teacher.id) {
      setBusy(true);
      dispatch(getTeacherLessons(teacher.id))
        .then((res) => {
          if (res.payload && res.payload.length) {
            setLessons(res.payload);
            if (res.payload.length) {
              setPreviousLessons(true);
            }
            setBusy(false);
          }
          setBusy(false);
        })
        .catch((err) => {
          setBusy(false);
        });
    }
  }, [refresh]);

  const onLessonSelect = (i) => {
    setIndex(i);

    dispatch(
      handleLessonChange({
        name: "lessonToSetup",
        value: {
          ...lessons[i],
          groupLessonAppointment: lessons[i].groupLessonAppointment.map(
            (app) => {
              return {
                ...app,
                startTime: convertTimezoneTwentyFour(
                  app.startTime,
                  "UTC",
                  timezone
                ),
                endTime: convertTimezoneTwentyFour(
                  app.endTime,
                  "UTC",
                  timezone
                ),
              };
            }
          ),
        },
      })
    );
  };

  useEffect(() => {
    if (!fetchedSubjects) {
      // what is this for?
      dispatch(setTeacherLessons(lessons));
    }
  }, [lessons]);

  let step = location.pathname.split("/")[3];

  const onAddLesson = () => {
    if (lessons.length > 3) {
      return toast.error("You can teach a maximum number of 3 lessons");
    }

    let newArray = [...lessons, initial];
    setLessons([...lessons, initial]);
    dispatch(handleLessonChange({ name: "lessonToSetup", value: initial }));
    setIndex(newArray.length);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleDelete = () => {
    index === deletingIndex && setIndex(undefined);
    if (lessons[deletingIndex].id) {
      dispatch(deleteLesson(lessons[deletingIndex].id));
    }
    setLessons((lessons) => {
      return [
        ...lessons.slice(0, deletingIndex),
        ...lessons.slice(deletingIndex + 1),
      ];
    });
    return setDeletingIndex(undefined);
  };
  const onRemoveLesson = (i) => {
    setDeletingIndex(i);
    setDeleting(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Custom message for the confirmation dialog
      // Note: Most modern browsers no longer display this message to users, but will show a default message instead.
      const message = "Are you sure you want to leave?";

      e.returnValue = message; // Chrome requires returnValue to be set.
      return message; // For other browsers
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const onManageClick = () => {
    if (lessons?.length < 1) {
      return toast.error(
        "Please add at least one listing to show on our profile"
      );
    }
    customLinks
      ? navigate("/teachers/subjects/2")
      : navigate("/teacher/signup/completed");
  };

  const [showGuide, setShowGuide] = useState(false);

  const steps = [
    {
      element: ".aside2",
      title: "Manage Subjects",
      intro: `Here you can view, edit and delete the lessons you teacher`,
    },
    {
      element: ".item",
      title: "Subject",
      intro: `here you can select the subject you want to view, edit or delete`,
    },
    {
      element: ".add_Lesson",
      title: "Add a new Lesson",
      intro: `Click this button to create a new lesson`,
    },
    {
      element: ".mng_button",
      title: "Manage Availability",
      intro: `Click here to view or edit your availability`,
    },
  ];
  useEffect(() => {
    if (!busy) setShowGuide(true);
  }, [busy]);
  const resetLessonIndexFn = useSelector(
    (state) => state.teacher.resetLessonIndex
  );

  useEffect(() => {
    setIndex(undefined);
  }, [resetLessonIndexFn]);

  return busy ? (
    <div className="spinner_container_startCourse">
      <div className="spinner" />
    </div>
  ) : (
    <div className={`setup__lessons__page ${customClass ? customClass : ""}`}>
      {/* <h1>{resetLessonIndexFn}</h1> */}
      {!!deleting && !!(deletingIndex || deletingIndex === 0) && (
        <AlertModalDelete
          type="lesson"
          handleDelete={() => handleDelete()}
          setModal={() => setDeleting(false)}
        />
      )}
      {track && <Track step={step} givenData={given} />}
      {showGuide && loc && (
        <Steps
          steps={steps}
          enabled={user.helpGuide}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="inner_max custom_inner_max">
        <div className="setup__lesson__container">
          <h1>{loc ? "Manage" : "Add your"} listings</h1>

          <div
            className={
              loc
                ? "lesson__inner__container lesson__inner__container2"
                : "lesson__inner__container"
            }
          >
            {index === undefined ? (
              <div className={loc ? "aside aside2" : "aside"}>
                {!loc && <h3>Listings & pricing</h3>}

                <>
                  <div className="flex_space">
                    {lessons.length ? (
                      <h4>Please select a listings to edit</h4>
                    ) : (
                      <h4>Please add a listing with your prices </h4>
                    )}
                  </div>
                  <div className="items">
                    {lessons &&
                      lessons.length > 0 &&
                      lessons.map((sub, i) => (
                        <AsideItem
                          text={sub.title}
                          setIndex={() => onLessonSelect(i)}
                          key={i}
                          i={i}
                          onRemoveLesson={onRemoveLesson}
                          location={location.pathname.includes("teachers")}
                          index={index}
                          lessonsLength={lessons.length}
                        />
                      ))}
                  </div>

                  <div className="flex_space final_step_btns">
                    <button
                      className="blue add_Lesson"
                      onClick={() => onAddLesson()}
                    >
                      Add new lesson
                    </button>
                    {loc ? (
                      <button
                        className="green add_Lesson mng_button"
                        onClick={() => onManageClick()}
                      >
                        Manage Availability
                      </button>
                    ) : (
                      <button
                        className={
                          lessons.length
                            ? "green add_Lesson"
                            : "green add_Lesson fetchings"
                        }
                        onClick={() => onManageClick()}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </>
              </div>
            ) : (
              <LessonInput
                setRefresh={setRefresh}
                refresh={refresh}
                previousLessons={previousLessons}
                handleLessonChange={handleLessonChange}
                lessons={lessons}
                index={index}
                setIndex={setIndex}
                teacher={teacher}
                loc={loc}
                customLinks={customLinks}
              />
            )}
            {!loc && (
              <div className="demo_video">
                <Note
                  p1="Please add your listings and prices to complete your profile. Your profile will not be visible to students without this step."
                  title="Complete Your Profile with Your Listings"
                />
                <h1>Learn to create a lesson easily—watch this video!</h1>
                <video width="100%" height="auto" controls>
                  <source
                    src={
                      "https://res.cloudinary.com/dmj4b3iwg/video/upload/v1709210305/videos/pc09hhtuuh7idz3fxhgs.mp4"
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const AsideItem = ({
  text,
  setIndex,
  i,
  index,
  onRemoveLesson,
  lessonsLength,
}) => {
  return (
    <div className={`item ${i === index ? "selected" : ""}`}>
      <div className="flex_center" onClick={() => setIndex(i)}>
        <div className="arrow">
          <KeyboardArrowRightIcon
            style={{ color: "#00a3ff" }}
            fontSize="large"
          />
        </div>
        <span>{text}</span>{" "}
      </div>
      {lessonsLength > 1 && (
        <HighlightOffIcon onClick={() => onRemoveLesson(i)} className="Red" />
      )}
    </div>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SharedSingleImageUploader, {
  GoodToKnow,
} from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSliceChange,
  registerTeacher,
} from "../../../ReduxToolkit/Slices/teacherSlice";

import Button from "../../../Components/Button/Button";
import { toast } from "react-toastify";
import Note from "../../Components/Note/Note";
import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";
import Toggle from "react-toggle";

const SignupStepTen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const isYoutube = useSelector(
    (state) => state?.teacher?.teacherToRegister?.youtube
  );
  const availabilitesToAdd = useSelector(
    (state) => state?.teacher?.availabilitesToAdd
  );
  const teacherStudioImages = useSelector(
    (state) => state?.teacher?.teacherToRegister?.studioImages
  );

  const firebaseToken = useSelector(
    (state) => state.teacher?.user?.firebaseToken
  );
  const bySocial = useSelector((state) => state.teacher?.user?.bySocial);
  const user = useSelector((state) => state.teacher?.user);
  const [studioImages, setStudioImages] = useState(teacherStudioImages ?? []);
  const [youtube, setYoutube] = useState(isYoutube);
  const fileTypes = ["JPEG", "webp", "JPG", "PNG"];
  const onSelectStudioFile = (value) => {
    setStudioImages(value);
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, studioImages: value },
      })
    );
  };

  const {
    name,
    lastName,
    gender,
    email,
    languagesSpoken,
    backgroundCheck,
    geoLocation,
    profileImage,
    selectedCategory,
    selectedSubject,
    travelDistance,
    lessonTitle,
    profileTitle,
    phone,
    password,
    selectedLocations,
    aboutLesson,
    certificates,
    experience,
    education,
  } = teacherToRegister;

  const handleSubmit = () => {
    if (!availabilitesToAdd?.length) {
      navigate("/teacher-signup/step-eight");
      return toast.warning("Missing availabilities!");
    }
    if (!experience?.length) {
      navigate("/teacher-signup/step-nine");
      return toast.warning("Missing info!");
    }
    if (!certificates?.length) {
      navigate("/teacher-signup/step-ten");
      return toast.warning("Missing certificates!");
    }
    let teacherOnlinePrice = teacherToRegister["Online"];
    let teacherStudentPlace = teacherToRegister["Student place"];
    let teacherTeacherPlace = teacherToRegister["At your house"];
    let nationality = teacherToRegister.nationalities
      ? teacherToRegister.nationalities.join(", ")
      : "";
    let location = selectedLocations?.length
      ? selectedLocations?.map((loc) =>
          loc === "At your house"
            ? "teacherPlace"
            : loc === "Student place"
            ? "studentPlace"
            : "online"
        )
      : [];
    if (!name || !lastName || !gender || !phone || !password || !email) {
      navigate("/teacher-signup");
      return toast.warning("Please fill out missing information");
    }
    if (!selectedCategory?.id || !selectedSubject?.id) {
      navigate("/teacher-signup/step-one");
      return toast.warning("Please fill out missing information");
    }
    if (!profileTitle || !lessonTitle) {
      navigate("/teacher-signup/step-two");
      return toast.warning("Please fill out missing information");
    }
    if (!aboutLesson) {
      navigate("/teacher-signup/step-three");
      return toast.warning("Please fill out missing information");
    }
    if (!nationality) {
      navigate("/teacher-signup/step-four");
      return toast.warning("Please fill out missing information");
    }

    if (!location?.length) {
      navigate("/teacher-signup/step-five");
      return toast.warning("Please fill out missing information");
    }
    if (location === "online" && !teacherOnlinePrice) {
      navigate("/teacher-signup/step-seven");
      return toast.warning("Please fill out hourly rate");
    }
    if (location === "studentPlace" && !teacherStudentPlace) {
      navigate("/teacher-signup/step-seven");
      return toast.warning("Please fill out hourly rate");
    }
    if (location === "teacherPlace" && !teacherTeacherPlace) {
      navigate("/teacher-signup/step-seven");
      return toast.warning("Please fill out hourly rate");
    }

    if (!teacherStudentPlace && !teacherTeacherPlace && !teacherOnlinePrice) {
      navigate("/teacher-signup/step-seven");
      return toast.warning("Please fill out missing information");
    }

    if (!education) {
      navigate("/teacher-signup/step-nine");
      return toast.warning("Please fill out missing information");
    }
    if (!profileImage) {
      navigate("/teacher-signup/step-nine");
      return toast.warning("Please upload profile image");
    }
    if (video?.url || youtube) {
      let languages = languagesSpoken?.map((lang) => {
        return {
          name: lang.value,
          proficiency: "BEGINNER",
        };
      });
      if (!isYouTubeVideo(video) && youtube) {
        return toast.warning("Invalid youtube url");
      }

      if (!studioImages.length) {
        return toast.warning("Missing studio images");
      }
      dispatch(
        registerTeacher({
          user: {
            name,
            lastName,
            gender,
            phone,
            nationality,
            password,
            address: { street: "", state: "", city: "", country: "" },
            firebaseToken: firebaseToken ? firebaseToken : "",
            bySocial: bySocial ? bySocial : false,
            email,
            role: "TEACHER",
            profileImage,
            geoLocation,
          },
          description: aboutLesson,
          images: studioImages?.length ? studioImages : [],
          availabilities: availabilitesToAdd,
          background: backgroundCheck,
          languages,
          title: profileTitle,
          education,
          videos: [
            youtube
              ? { url: video, publicId: video }
              : video.url
              ? video
              : undefined,
          ],
          lesson: {
            location,
            title: lessonTitle,
            onlinePrice:
              !teacherOnlinePrice &&
              (Number(teacherOnlinePrice) * 100) / 2 !== null
                ? 0
                : (Number(teacherOnlinePrice) * 100) / 2,
            studentPlacePrice:
              !teacherStudentPlace &&
              (Number(teacherStudentPlace) * 100) / 2 !== null
                ? 0
                : (Number(teacherStudentPlace) * 100) / 2,
            teacherPlacePrice:
              !teacherTeacherPlace &&
              (Number(teacherStudentPlace) * 100) / 2 !== null
                ? 0
                : (Number(teacherTeacherPlace) * 100) / 2,
            level: "BEGINNER",
            travelDistance: Number(travelDistance),
            subjectIds: [selectedSubject?.id],
            categoryIds: [selectedCategory?.id],
          },
          experience,
          certifications: certificates,
          hourlyRate: 220,
          skills: [],
        })
      )
        .then((res) => {
          if (typeof res?.payload === "string") {
            if (res?.payload === "Email already in use") {
              navigate("/teacher-signup");
              return toast.warning(
                "Email already in use, please try again with a different email address"
              );
            }
            if (res.payload.toLowerCase().includes("firebase")) {
              dispatch(
                handleSliceChange({
                  name: "user",
                  value: { ...user, bySocial: false, firebaseToken: "" },
                })
              );

              dispatch(
                handleSliceChange({
                  name: "teacherToRegister",
                  value: { ...teacherToRegister, email: "" },
                })
              );
              toast.warning("Google sign in session expired, please try again");
              return navigate("/teacher-signup");
            }
          }
          if (res?.payload?.error?.includes("Missing")) {
            navigate("/teacher-signup");
            return toast.warning("Please fill out missing information");
          }
          if (res.type.includes("fulfilled")) {
            navigate("/teacher-signup/complete");
          } else {
            toast.error("something went wrong");
          }
        })
        .catch((Err) => console.log("err", Err));
    } else {
      if (!video?.url && !youtube) {
        toast.warning("Missing video");
      } else {
        if (youtube && !video) {
          toast.warning("Missing video");
        }
      }
    }
  };
  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 300;
  function isYouTubeVideo(url) {
    // Regular expression for various YouTube video URLs
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    // Test the URL against the regular expression
    return youtubeRegex.test(url);
  }
  const [video, setVideo] = useState(teacherToRegister?.video);

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          // images={[]}
          notes={[
            "Here, you can showcase your studio space, if you are teaching from home or a location. You can also add a good image even if you teach online to aid your visibility amongst other tutors.",
            "Profile Video: A good video will help you communicate faster to potential student and their parents that you are the one for them.",
            "Take this opportunity to introduce yourself and what you offer. You can upload a video or record it directly using your webcam. ( You have to enable the cam to do this! )",
            "NB: Pls, DO NOT ADD RANDOM VIDEOS FROM YOUTUBE. All videos must be yours.",
          ]}
          subtitle={
            "A good listing /lesson picture or photo makes your listing very attractive to potential student."
          }
          className={"self_start"}
        />
      </div>
      <div className="container_2">
        <h2 className="no_max" style={{ margin: "20px 0" }}>
          <span>Studio/Lesson Photos</span>
        </h2>
        <div className="signup_profile_image_uploader">
          <Note
            title={"Your Profile Photo"}
            p1={"This photo will appear in your public profile!"}
          />
          <SharedMultipleFileUploader
            fileTypes={fileTypes}
            setImages={onSelectStudioFile}
            images={studioImages}
            multiple={true}
          />
        </div>

        <h2 className="no_max" style={{ margin: "20px 0" }}>
          <span>Video intro</span>
        </h2>
        <div className="row">
          <p style={{ display: "flex", gap: "10px" }}>
            <span>Video / Youtube URL</span>
            <Toggle
              defaultChecked={youtube} // initial state of the switch button
              onChange={() => {
                dispatch(
                  handleSliceChange({
                    name: "teacherToRegister",
                    value: { ...teacherToRegister, youtube: !isYoutube },
                  })
                );
                setYoutube(!isYoutube);
                setVideo();
              }}
              icons={false} // hide the default icons to use custom ones
              checked={isYoutube}
            />
          </p>

          {/* <p>
                      {youtube ? "Or upload a video" : "Or add a youtube link"}
                    </p> */}
        </div>
        {!youtube ? (
          <SharedVideoUploader
            index={"file-video"}
            onVideoChange={(value) => {
              setVideo(value);
              dispatch(
                handleSliceChange({
                  name: "teacherToRegister",
                  value: { ...teacherToRegister, video: value },
                })
              );
            }}
            video={video}
            MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
            MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
            noToolTip={true}
          />
        ) : (
          <div className="yt_input">
            <h4>Video URL </h4>
            <span>ex. https://www.youtube.com/watch?v=dQw4w9WgXcQ</span>
            <input
              type="text"
              placeholder="Youtube video link"
              onChange={(e) => {
                if (
                  e.target.value ===
                    "https://www.youtube.com/watch?v=dQw4w9WgXcQ" ||
                  e.target.value.includes("dQw4w9WgXcQ")
                ) {
                  return toast.error(
                    "You should add your own youtube link and not the one in the example."
                  );
                } else {
                  setVideo(e.target.value);
                  dispatch(
                    handleSliceChange({
                      name: "teacherToRegister",
                      value: { ...teacherToRegister, video: e.target.value },
                    })
                  );
                }
              }}
              value={video}
              className="input"
            />
          </div>
        )}
        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-twelve")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepTen;
